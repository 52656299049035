"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ProjectsListing = function ProjectsListing() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $filterMobile = document.querySelector('.projects-listing .mobile-filters-container .mobile-filters');
    if ($filterMobile) {
      $filterMobile.addEventListener('change', function (e) {
        e.preventDefault();
        window.location.href = $filterMobile.querySelectorAll('option')[e.target.selectedIndex].dataset.href;
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();