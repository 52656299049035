"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.HomeHero = function HomeHero() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $homeHeroSlider = document.querySelector('.home-hero');
    if ($homeHeroSlider) {
      var flkty = new Flickity($homeHeroSlider, {
        // options
        cellAlign: 'left',
        contain: true,
        cellSelector: '.hero-item'
      });
      var changeIndex = function changeIndex(index) {
        var $slides = $homeHeroSlider.querySelectorAll('.hero-item');
        $slides.forEach(function ($slide) {
          var $iframe = $slide.querySelector('iframe');
          if ($slide.classList.contains('is-selected')) {
            $iframe.src = $iframe.src.replace('autoplay=0', 'autoplay=1');
          } else {
            $iframe.src = $iframe.src.replace('autoplay=1', 'autoplay=0');
          }
        });
      };
      flkty.on('change', function (index) {
        changeIndex(index);
      });
      changeIndex(0);
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();