"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Menu = function Menu() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $menu = document.querySelector('.header');
    window.addEventListener("scroll", function (event) {
      var scroll = window.scrollY;
      if (scroll > 0 && !$menu.classList.contains('small')) {
        $menu.classList.add('small');
      } else if (scroll == 0 && $menu.classList.contains('small')) {
        $menu.classList.remove('small');
      }
    });
    var $menuMobile = document.querySelector('.mobile-menu');
    var $openTrigger = document.querySelector('.header .mobile-trigger');
    var $closeTrigger = document.querySelector('.mobile-menu .mobile-trigger');
    $openTrigger.addEventListener('click', function (e) {
      e.preventDefault();
      $menuMobile.classList.add('is-active');
    });
    $closeTrigger.addEventListener('click', function (e) {
      e.preventDefault();
      $menuMobile.classList.remove('is-active');
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();